<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">企业内训</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学习数据</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl" style="border-bottom: none;">
            <div class="searchbox" style="margin-bottom: 5px">
                <div title="任务名称" class="searchboxItem searchboxItems">
                    <span class="itemLabel">任务名称:</span>
                    <el-input
                        v-model="ruleForm.projectName"
                        type="text"
                        size="small"
                        placeholder="请输入任务名称"
                        clearable
                        style="flex: 1"
                    />
                </div>
                <div title="任务状态" class="searchboxItem searchboxItems">
                    <span class="itemLabel">任务状态:</span>
                    <el-select
                        size="small"
                        v-model="ruleForm.projectState"
                        placeholder="请选择任务状态"
                        clearable
                    >
                        <el-option
                        v-for="item in ClassstatusList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        ></el-option>
                    </el-select>
                </div>
                <div v-if="userJson.roleId!='-6'" title="所属企业" class="searchboxItem ci-full">
                    <span class="itemLabel">所属企业:</span>
                    <el-select
                    size="small"
                    v-model="ruleForm.compId"
                    remote
                    :remote-method="getuserCompanyList"
                    filterable
                    clearable
                    placeholder="请至少输入两个字搜索"
                    style="width: 100%"
                    >
                    <el-option
                        v-for="(item, index) in userCompanyList"
                        :key="index"
                        :label="item.compName"
                        :value="item.compId"
                    ></el-option>
                    </el-select>
                </div>
                <div v-else title="开始时间" class="searchboxItem searchboxItems">
                    <span class="itemLabel">开始时间:</span>
                    <el-date-picker
                        clearable
                        size="small"
                        v-model="ruleForm.startDate"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                    ></el-date-picker>
                </div>
            </div>
        </div>
        <div class="operationControl" style="border-bottom: none;">
            <div class="searchbox" style="margin-bottom: 5px">
                <div v-if="userJson.roleId!='-6'" title="开始时间" class="searchboxItem searchboxItems">
                    <span class="itemLabel">开始时间:</span>
                    <el-date-picker
                        clearable
                        size="small"
                        v-model="ruleForm.startDate"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                    ></el-date-picker>
                </div>
                <div title="结束时间" class="searchboxItem searchboxItems">
                    <span class="itemLabel">结束时间:</span>
                    <el-date-picker
                        clearable
                        size="small"
                        v-model="ruleForm.endDate"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                    ></el-date-picker>
                </div>
                <div class="btnBox" style="margin-left: 20px">
                    <el-button
                    style="margin-left: 20px"
                    class="bgc-bv"
                    round
                    @click="getData()"
                    >查询</el-button
                    >
                    <el-button class="bgc-bv" round @click="exportData"
                    >导出</el-button
                    >
                </div>
            </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed="left"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="培训任务编码"
                align="left"
                prop="projectCode"
                show-overflow-tooltip
                fixed="left"
                min-width="200"
              />
              <el-table-column
                label="任务名称"
                align="left"
                prop="projectName"
                show-overflow-tooltip
                fixed="left"
                min-width="200"
              />
              <el-table-column
                v-if="userJson.roleId!='-6'" 
                label="所属企业"
                align="left"
                prop="compName"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="有效期"
                align="left"
                prop="effectiveDate"
                show-overflow-tooltip
                min-width="200"
              />
              <el-table-column
                label="任务状态"
                align="left"
                prop="projectState"
                show-overflow-tooltip
                min-width="80"
              />
              <el-table-column
                label="关联人数"
                align="left"
                prop="relationPersonNum"
                show-overflow-tooltip
                min-width="80"
              />
              <el-table-column
                label="参与人数"
                align="left"
                prop="participatePersonNum"
                show-overflow-tooltip
                min-width="80"
              />
              <el-table-column
                label="完成人数"
                align="left"
                prop="graduationPersonNum"
                show-overflow-tooltip
                min-width="80"
              />
              <el-table-column
                label="创建时间"
                align="left"
                prop="createTime"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="操作"
                align="center"
                width="150"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="examine(scope.row)"
                    >查看</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "platformOfquestionBankList",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      ruleForm: {
        startDate:'',//开始时间
        endDate:'',//结束时间
        projectName:'',//任务名称
        projectState:'',//任务状态
        compId:'',//所属企业
      },
      ClassstatusList:[
        {
          label: "未开始",
          value: "10"
        },
        {
          label: "进行中",
          value: "20"
        },
        {
          label: "已结束",
          value: "30"
        }
      ],//任务状态list
      userCompanyList:[],//所属企业list
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser"
    }),
  },
  created() {
    this.getTableHeight();
    // this.getclassstatusList()
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.ruleForm.projectName) {
        params.projectName = this.ruleForm.projectName;
      }
      if (this.ruleForm.projectState) {
        params.projectState = this.ruleForm.projectState;
      }
      if (this.ruleForm.compId) {
        params.compId = this.ruleForm.compId;
      }
      if (this.ruleForm.startDate&&this.ruleForm.startDate.length===2) {
        params.startDateBegin = this.ruleForm.startDate[0];
        params.startDateEnd = this.ruleForm.startDate[1];
      }
      if (this.ruleForm.endDate&&this.ruleForm.endDate.length===2) {
        params.endDateBegin = this.ruleForm.endDate[0];
        params.endDateEnd = this.ruleForm.endDate[1];
      }
      this.doFetch({
        url: "/biz/learning/dataList",
        params,
        pageNum,
      });
    },
    // 任务状态list
    getclassstatusList() {
      const classstatuslist = this.$setDictionary("PROJECTSTATE", "list");
      const list = [];
      for (const key in classstatuslist) {
        list.push({
          value: key,
          label: classstatuslist[key],
        });
      }
      this.ClassstatusList = list.filter(e=>e.value=='10'||e.value=='20'||e.value=='30');
    },
    /* 所属企业 */
    getuserCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.userCompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.userCompanyList = [];
      }
    },
    // 导出
    exportData() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
            const params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            };
            if (this.ruleForm.projectName) {
                params.projectName = this.ruleForm.projectName;
            }
            if (this.ruleForm.projectState) {
                params.projectState = this.ruleForm.projectState;
            }
            if (this.ruleForm.compId) {
                params.compId = this.ruleForm.compId;
            }
            if (this.ruleForm.startDate&&this.ruleForm.startDate.length===2) {
                params.startDateBegin = this.ruleForm.startDate[0];
                params.startDateEnd = this.ruleForm.startDate[1];
            }
            if (this.ruleForm.endDate&&this.ruleForm.endDate.length===2) {
                params.endDateBegin = this.ruleForm.endDate[0];
                params.endDateEnd = this.ruleForm.endDate[1];
            }
            this.$post("/biz/learning/export", params).then((res) => {
                if (res.status == "0") {
                let list = res.data;
                for (let item of list) {
                    console.log(item);
                    if (!this.downloadItems.includes(item.taskId)) {
                    this.$store.dispatch("pushDownloadItems", item.taskId);
                    } else {
                    this.$message.warning(
                        "[" + item.fileName + "]已经申请下载,请耐心等待"
                    );
                    }
                }
                } else {
                    this.$message.error(res.message);
                }
            });
        })
        .catch(() => {});
    },
    // 查看
    examine(row){
      this.$router.push({
        path:'/web/CustomPrograms/study/studyDetails',
        query:{
          projectId:row.projectId
        }
      })
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 1) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 64;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">

</style>

